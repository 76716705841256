import React from 'react';
import { useState, useEffect,useContext} from "react";
import { useNavigate } from "react-router-dom";

import { app } from "../Connectors/firebase";
import { doc, query, onSnapshot, getFirestore, connectFirestoreEmulator, deleteDoc,collection} from "firebase/firestore"

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';
import { Box } from "@mui/material";

import FeatureTitle from '../Utils/featureTitle';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import {generateImprovements} from '../Connectors/firebase';



import CircularProgress from '@mui/material/CircularProgress';
import Card from '../Utils/Card';
import {ScreenDialog} from '../Utils/ScreenDialog';

import { LanguageContext } from "../Utils/multilingualCtx";

import MultiLingualContent from '../Utils/MultilingualContent';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import createList from '../Utils/CreateList';

const db = getFirestore(app);

/*if(process.env.NODE_ENV !== "production")
{
  connectFirestoreEmulator(db, 'localhost', 8080);
}*/

function mapLng(lng)
{
    switch(lng)
    {
        case "spanish": return "ES-ES"; break;
        case "english": return "EN"; break;
        case "german": return "DE"; break;
        case "french": return "FR"; break;
        case "italian": return "IT"; break;
        case "catalan": return "ES-CA"; break;
    }
}


export default function NewImprovement(props) {
  const [textInput, setTextInput] = useState("");
  const [improvementOutput, SetImprovementOutput] = useState([]);
  const [DisplayError, setDisplayError] = useState(undefined);
  const [loading, setLoading]  = useState(false);

  const {language} = useContext(LanguageContext)
  const [destLng, setdestLng]  = useState(mapLng(language.toLowerCase()));


  //Get current project generated texts.
  useEffect(() => {
        let unsuscribe=undefined;
        if(props.uid!==undefined)
        {
            const userProjects = query(collection(db, '/users/'+props.uid+'/projects/'+props.projectSelected+'/improvements'));
            unsuscribe = onSnapshot(userProjects, (doc) => {  
                if (doc.size) {
                    let list=[];
                    let count = 0;
                    doc.forEach((doc) => {
                        list.unshift(createList(doc.id, doc.data().text, doc.data().love !== undefined ? doc.data().love : false, doc.data().userPrompt !== undefined ? doc.data().userPrompt:""));
                        count= count+1;
                    });            
                    SetImprovementOutput(list);

                }else{
                    SetImprovementOutput([]);
                    console.log("Could not get docs... Retry, please.");
                }    
                
                if(props.args!==false)
                {
                    setTextInput(props.args);
                }
            });
        }
        return () => {
            unsuscribe();
        }
    }, [props.projectSelected]);

    const changeDestinationLanguage= (event) =>{
        setdestLng(event.target.value)
    }

  function generate()
  {
    setLoading(true); 
    
    
    var errorMessage=undefined

    if(textInput!=='')
    {
        
        generateImprovements({
            uid: props.uid,
            pid: props.projectSelected,
            text: textInput,
            lng: destLng,
        }).then((result) => {

            try {
                const jdata = JSON.parse(result.data);
                
                switch(jdata.error)
                {
                   case "flaggedContent":
                        errorMessage ={
                            title:<MultiLingualContent contentID="moderation_alert_title"/>,
                            msg: <MultiLingualContent contentID="moderation_alert_text"/>,
                            buttons: {
                                b1: {
                                    text: <MultiLingualContent contentID="ok"/>,
                                    section: null,
                                    cb: handleClose
                                },
                                b2: {
                                    text: <MultiLingualContent contentID="send_feedback"/>,
                                    section: "Send feedback",
                                    cb: handleFeedback
                                },
                            },
                            other:{
                                select:null
                            },
                            handleClose: handleClose
                        }
                        setLoading(false); 
                        setDisplayError(errorMessage)
                        break;
                    case "noText":
                        errorMessage ={
                            title:<MultiLingualContent contentID="no_text_provided_alert_title"/>,
                            msg: <MultiLingualContent contentID="no_text_provided_alert_text"/>,
                            buttons: {
                                b1: {
                                    text: <MultiLingualContent contentID="ok"/>,
                                    section: null,
                                    cb: handleClose
                                },
                                b2: null,
                            },
                            other:{
                                select:null
                            },
                            handleClose: handleClose
                        }
                        setLoading(false); 
                        setDisplayError(errorMessage)
                        break;
                    case "noCredits":
                        errorMessage={
                            title:<MultiLingualContent contentID="no_credits_alert_title"/>,
                            msg: <MultiLingualContent contentID="no_credits_alert_text"/>,
                            buttons: {
                                b1: {
                                    text: <MultiLingualContent contentID="get_credits"/>,
                                    section: "Get Credits",
                                    cb: handleBuy
                                },
                                b2: {
                                    text: <MultiLingualContent contentID="cancel"/>,
                                    section: null,
                                    cb: handleClose
                                }
                            },
                            other:{
                                select:null
                            },
                            handleClose: handleClose
                        }
                        setLoading(false); 

                        setDisplayError(errorMessage)
                        break;
                    case "noUser":
                        setLoading(false); 
                        break;
                    case "noProject":
                        setLoading(false); 
                        break;
                    default:
                        setLoading(false); 
                        break; 
                }

            } catch(e) {  
                const s=[improvementOutput.length+1,result.data];
                SetImprovementOutput((oldList) =>{
                    
                    setLoading(false); 

                    if(oldList[0]!==undefined)
                    {
                        return [...oldList,s]
                    }
                    else
                    {
                        return [s]
                    }
                });
            }
        }).catch((error) => {
            setLoading(false); 
            console.log("ERROR");
            console.log(`error: ${JSON.stringify(error)}`);

        });
    }
    else
    {
       errorMessage ={
            title:<MultiLingualContent contentID="no_text_provided_alert_title"/>,
            msg: <MultiLingualContent contentID="no_text_provided_alert_text"/>,
            buttons: {
                b1: {
                    text: <MultiLingualContent contentID="ok"/>,
                    section: null,
                    cb: handleClose
                },
                b2: null,
            },
            other:{
                select:null
            },
            handleClose: handleClose
        }
        setLoading(false); 

        setDisplayError(errorMessage)

    }
}

 

  function del(entryId)
  {
      //Borrar de la variable global y renderizar la pantalla otra vez
      let retIdx=-1;
      improvementOutput.forEach((val, idx, arr)=>{
        if(val.id === entryId)
        {
            retIdx = idx;
        }
      })
      if (retIdx > -1) {
        deleteDoc(doc(db,"users", props.uid, "projects",props.projectSelected,"improvements", entryId)).then(()=>{
            improvementOutput.splice(retIdx, 1);
            SetImprovementOutput([...improvementOutput]);
        }).catch((e)=>{

            console.log(e);
            alert(<MultiLingualContent contentID="error_deleting_completion_alert_text"/>)
        });

      }
      
  }
    const handleBuy = () => {
    //Go to section ...
    setDisplayError(undefined)
    props.sectionSelectorCB("Get Credits")
  };

  const handleFeedback = () => {
    //Go to section ...
    setDisplayError(undefined)
    props.sectionSelectorCB("Feedback")
  };
  const handleClose = () => {
    setDisplayError(undefined)
  };
  const placeholder_text = MultiLingualContent({contentID:"improve_feature_placeholder_1"});

  return (
        <>
        {
            DisplayError!==undefined?(
                <ScreenDialog DisplayError={DisplayError}/>
                ):(null)
        }
        {
            props.projectList!=null?(
        <React.Fragment>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <FeatureTitle><MultiLingualContent contentID="improve_feature_title"/></FeatureTitle>
            <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                paddingBottom:1,
            }}>
        
            <TextField
                size="small"
                multiline
                maxRows={4}
                placeholder={placeholder_text}
                value={textInput}
                onChange={(e) => setTextInput(e.target.value)}
                autoFocus={true}
            />
            </Box>
           
            <Stack spacing={2} direction="row">
                {//<SetToneWidget></SetToneWidget>
                }
                    <Button variant="contained" onClick={generate}>
                    {
                        !!loading?(<><CircularProgress size="1rem" color="secondary"/><div style={{marginLeft:7}}><MultiLingualContent contentID="improve_feature_button_text"/></div></>):(<div><MultiLingualContent contentID="improve_feature_button_text"/></div>)                        
                    }
                    </Button>

                    <Select
                      labelId="DestLngSelect"
                      id="DestLngSelect"
                      value={destLng}
                      label={<MultiLingualContent contentID="select_output_lng"/>}
                      onChange={changeDestinationLanguage}
                    >
                        <MenuItem value="ES-ES">Español</MenuItem>
                        <MenuItem value="EN">English</MenuItem>
                        <MenuItem value="DE">Deutsch</MenuItem>
                        <MenuItem value="ES-CA">Català</MenuItem>
                        <MenuItem value="FR">Français</MenuItem>
                        <MenuItem value="IT">Italiano</MenuItem>
                    </Select>
            </Stack>


        </Paper>
        <Grid container spacing={2} sx={{mt:2, mb:2}}>
        {improvementOutput.length>0 ?(improvementOutput.map(entry =>
        
        <Card key = {entry.id} cid = {entry.id} content = {entry.text} love={entry.love} cb={()=>del(entry.id)} color={()=> {return Math.floor(Math.random() * 11)}} projectSelected={props.projectSelected} uid={props.uid} projectList={props.projectList} sectionSelectorCB={props.sectionSelectorCB} col="improvements" textInputCB={setTextInput} userPrompt={entry.userPrompt}/>
            )):(null)
        }
        
        </Grid>
        </React.Fragment>
            ):(null)
        }
        </>
    );
}