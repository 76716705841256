import React from 'react';
import { useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";

import { app } from "../Connectors/firebase";
import { doc, query, onSnapshot, getFirestore, connectFirestoreEmulator, deleteDoc,collection} from "firebase/firestore"

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';
import { Box } from "@mui/material";

import FeatureTitle from '../Utils/featureTitle';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import {sendFeedback} from '../Connectors/firebase';



import CircularProgress from '@mui/material/CircularProgress';
import Card from '../Utils/Card';
import {ScreenDialog} from '../Utils/ScreenDialog';
import MultiLingualContent from '../Utils/MultilingualContent';


const db = getFirestore(app);

/*if(process.env.NODE_ENV !== "production")
{
  connectFirestoreEmulator(db, 'localhost', 8080);
}*/

export default function NewFeedback(props) {
  const [textInput, setTextInput] = useState("");
  const [improvementOutput, SetImprovementOutput] = useState([]);
  const [DisplayError, setDisplayError] = useState(undefined);
  const [loading, setLoading]  = useState(false);

  useEffect(() => {
        if(props.args!==false)
        {
            setTextInput(props.args);
        }
    }, [props]);

  const handleClose = () => {
    setDisplayError(undefined)
  };


 
  function generate()
  {
    setLoading(true); 
    
    
    var errorMessage=undefined

    if(textInput!=='')
    {
        
        sendFeedback({
            uid: props.uid,            
            text: textInput
        }).then((result) => {

            try {
                const jdata = JSON.parse(result.data);
                
                switch(jdata.error)
                {
                    case "noText":
                        errorMessage ={
                            title:"No text provided",
                            msg: "We need a text in order to process your petition.",
                            buttons: {
                                b1: {
                                    text: "Ok",
                                    section: null,
                                    cb: handleClose
                                },
                                b2: null,
                            },
                            other:{
                                select:null
                            },
                            handleClose: handleClose
                        }
                        setLoading(false); 
                        setDisplayError(errorMessage)
                        break;
                    case "noFeedback":
                        errorMessage={
                            title:"Could not send feedback",
                            msg: "Uops! Please retry sending us your feedback and if this message keeps showing up please send it to the following address: info@rocketme.es. Your feedback is highly appreciated and will help us to improve. Sorry for the inconvenience this may cause.",
                            buttons: {
                                b1: {
                                    text: "Ok",
                                    section: null,
                                    cb: handleClose
                                },
                                b2: null,
                            },
                            other:{
                                select:null
                            },
                            handleClose: handleClose
                        }
                        setLoading(false); 

                        setDisplayError(errorMessage)
                        break;
                    default:
                        errorMessage ={
                            title:"Thank you",
                            msg: "Your feedback is important to us. We appreciate your help.",
                            buttons: {
                                b1: {
                                    text: "Close",
                                    section: null,
                                    cb: handleClose
                                },
                                b2: null,
                            },
                            other:{
                                select:null
                            },
                            handleClose: handleClose
                        }
                        setLoading(false); 
                
                        setDisplayError(errorMessage)
                        break; 
                }

            } catch(e) {  
                const s=[improvementOutput.length+1,result.data];
                SetImprovementOutput((oldList) =>{
                    
                    setLoading(false); 

                    if(oldList[0]!==undefined)
                    {
                        return [...oldList,s]
                    }
                    else
                    {
                        return [s]
                    }
                });
            }
        }).catch((error) => {
            setLoading(false); 
            console.log("ERROR");
            console.log(`error: ${JSON.stringify(error)}`);

        });
    }
    else
    {
        errorMessage ={
            title:"No text provided",
            msg: "We need a text in order to process your petition.",
            buttons: {
                b1: {
                    text: "Ok",
                    section: null,
                    cb: handleClose
                },
                b2: null,
            },
            other:{
                select:null
            },
            handleClose: handleClose
        }
        setLoading(false); 

        setDisplayError(errorMessage)

    }
}

const placeholder_text = MultiLingualContent({contentID:"feedback_feature_placeholder_1"});

  return (
        <>
        {
            DisplayError!==undefined?(
                <ScreenDialog DisplayError={DisplayError}/>
                ):(null)
        }
        {
            props.projectList!=null?(
        <React.Fragment>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <FeatureTitle><MultiLingualContent contentID="feedback_feature_title"/></FeatureTitle>
            <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                paddingBottom:1,
            }}>
        
            <TextField
                size="small"
                multiline
                maxRows={20}
                placeholder={placeholder_text}
                value={textInput}
                onChange={(e) => setTextInput(e.target.value)}
                autoFocus={true}
            />
            </Box>
           
            <Stack spacing={2} direction="row">
                {//<SetToneWidget></SetToneWidget>
                }
                <Button variant="contained" onClick={generate}>
                    {
                        !!loading?(<><CircularProgress size="1rem" color="secondary"/><div style={{marginLeft:7}}><MultiLingualContent contentID="feedback_feature_button_text"/></div></>):(<div><MultiLingualContent contentID="feedback_feature_button_text"/></div>)                        
                    }</Button>
            </Stack>


        </Paper>
        </React.Fragment>
            ):(null)
        }
        </>
    );
}