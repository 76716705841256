import React from 'react';
import { useState, useEffect, useContext} from "react";
import { useNavigate } from "react-router-dom";

import { app } from "../Connectors/firebase";
import { doc, query, onSnapshot, getFirestore, connectFirestoreEmulator, deleteDoc,collection} from "firebase/firestore"

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';
import { Box } from "@mui/material";

import FeatureTitle from '../Utils/featureTitle';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import {generateQuestions} from '../Connectors/firebase';


import CircularProgress from '@mui/material/CircularProgress';
import Card from '../Utils/Card';
import {ScreenDialog} from '../Utils/ScreenDialog';
import { LanguageContext } from "../Utils/multilingualCtx";

import MultiLingualContent from '../Utils/MultilingualContent';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import createList from '../Utils/CreateList';


const db = getFirestore(app);

/*if(process.env.NODE_ENV !== "production")
{
  connectFirestoreEmulator(db, 'localhost', 8080);
}*/

function mapLng(lng)
{
    switch(lng)
    {
        case "spanish": return "ES-ES"; break;
        case "english": return "EN"; break;
        case "german": return "DE"; break;
        case "french": return "FR"; break;
        case "italian": return "IT"; break;
        case "catalan": return "ES-CA"; break;
    }
}

export default function NewMeetingNotes(props) {
  const [textInput, setTextInput] = useState("");
  const [questionsOutput, setQuestionsOutput] = useState([]);
  const [questionsCount, setQuestionsCount] = useState(2);
  const [DisplayError, setDisplayError] = useState(undefined);
  const [loading, setLoading]  = useState(false);
  const [numQuestions, setNumQuestions]  = useState("5");


  const {language} = useContext(LanguageContext)
  const [destLng, setdestLng]  = useState(mapLng(language.toLowerCase()));


  //Get current project generated texts.
  useEffect(() => {
        let unsuscribe=undefined;
        if(props.uid!==undefined)
        {
            const userProjects = query(collection(db, '/users/'+props.uid+'/projects/'+props.projectSelected+'/questions'));
            unsuscribe = onSnapshot(userProjects, (doc) => {  
                if (doc.size) {
                    let list=[];
                    doc.forEach((doc) => {
                        list.unshift(createList(doc.id, doc.data().text, doc.data().love !== undefined ? doc.data().love : false, doc.data().userPrompt !== undefined ? doc.data().userPrompt:""));
                    });            
                    setQuestionsOutput(list);
                }else{
                    setQuestionsOutput([]);
                    console.log("Could not get docs... Retry, please.");
                }        
            });
        }
        return () => {
            if(props.uid!==undefined)
            {
                unsuscribe();
            }
        }
    }, [props]);

    const changeDestinationLanguage= (event) =>{
        setdestLng(event.target.value)
    }

  function generate()
  {
    setLoading(true); 
    console.log(JSON.stringify({text: textInput}));

    setQuestionsCount((old)=> old+1);
    var errorMessage=undefined

    if(textInput!=='')
    {
        
        generateQuestions({
            uid: props.uid,
            pid: props.projectSelected,
            text: textInput,
            numQuestions: numQuestions,
            lng: destLng,
        }).then((result) => {

            try {
                const jdata = JSON.parse(result.data);
                
                switch(jdata.error)
                {
                   case "flaggedContent":
                        errorMessage ={
                            title:<MultiLingualContent contentID="moderation_alert_title"/>,
                            msg: <MultiLingualContent contentID="moderation_alert_text"/>,
                            buttons: {
                                b1: {
                                    text: <MultiLingualContent contentID="ok"/>,
                                    section: null,
                                    cb: handleClose
                                },
                                b2: {
                                    text: <MultiLingualContent contentID="send_feedback"/>,
                                    section: "Send feedback",
                                    cb: handleFeedback
                                },
                            },
                            other:{
                                select:null
                            },
                            handleClose: handleClose
                        }
                        setLoading(false); 
                        setDisplayError(errorMessage)
                        break;
                    case "noText":
                        errorMessage ={
                            title:<MultiLingualContent contentID="no_text_provided_alert_title"/>,
                            msg: <MultiLingualContent contentID="no_text_provided_alert_text"/>,
                            buttons: {
                                b1: {
                                    text: <MultiLingualContent contentID="ok"/>,
                                    section: null,
                                    cb: handleClose
                                },
                                b2: null,
                            },
                            other:{
                                select:null
                            },
                            handleClose: handleClose
                        }
                        setLoading(false); 
                        setDisplayError(errorMessage)
                        break;
                    case "noCredits":
                        errorMessage={
                            title:<MultiLingualContent contentID="no_credits_alert_title"/>,
                            msg: <MultiLingualContent contentID="no_credits_alert_text"/>,
                            buttons: {
                                b1: {
                                    text: <MultiLingualContent contentID="get_credits"/>,
                                    section: "Get Credits",
                                    cb: handleBuy
                                },
                                b2: {
                                    text: <MultiLingualContent contentID="cancel"/>,
                                    section: null,
                                    cb: handleClose
                                }
                            },
                            other:{
                                select:null
                            },
                            handleClose: handleClose
                        }
                        setLoading(false); 

                        setDisplayError(errorMessage)
                        break;
                    case "noUser":

                        break;
                    case "noProject":

                        break;
                    default:
                        setLoading(false); 
                        break; 
                }

            } catch(e) {       
                const s=[questionsCount,result.data];
                setQuestionsOutput((oldList) =>{ 
                    if(oldList[0]!==undefined)
                    {
                        setLoading(false); 

                        return [...oldList,s]
                    }else
                    {
                        setLoading(false); 

                        return [s]
                    }
                });
            }
        }).catch((error) => {
            setLoading(false); 

            console.log(`error: ${JSON.stringify(error)}`);

        });
    }
    else
    {
       errorMessage ={
            title:<MultiLingualContent contentID="no_text_provided_alert_title"/>,
            msg: <MultiLingualContent contentID="no_text_provided_alert_text"/>,
            buttons: {
                b1: {
                    text: <MultiLingualContent contentID="ok"/>,
                    section: null,
                    cb: handleClose
                },
                b2: null,
            },
            other:{
                select:null
            },
            handleClose: handleClose
        }
        setLoading(false); 

        setDisplayError(errorMessage)

    }
}

 

  function del(entryId)
  {
      //Borrar de la variable global y renderizar la pantalla otra vez
      let retIdx=-1;
      questionsOutput.forEach((val, idx, arr)=>{
        if(val.id === entryId)
        {
            retIdx = idx;
        }
      })
      if (retIdx > -1) {
        deleteDoc(doc(db,"users", props.uid, "projects",props.projectSelected,"questions", entryId)).then(()=>{
            questionsOutput.splice(retIdx, 1);
            setQuestionsOutput([...questionsOutput]);
        }).catch((e)=>{

            console.log(e);
            alert(<MultiLingualContent contentID="error_deleting_completion_alert_text"/>)
        });

      }
      
  }
    const handleBuy = () => {
    //Go to section ...
    setDisplayError(undefined)
    props.sectionSelectorCB("Get Credits")
  };

  const handleFeedback = () => {
    //Go to section ...
    setDisplayError(undefined)
    props.sectionSelectorCB("Feedback")
  };
  const handleClose = () => {
    setDisplayError(undefined)
  };

  const changeNumQuestions= (event) =>{
    setNumQuestions(event.target.value)
  }

  const placeholder_text = MultiLingualContent({contentID:"questions_feature_placeholder_1"});

  return (
        <>
        {
            DisplayError!==undefined?(
                <ScreenDialog DisplayError={DisplayError}/>
                ):(null)
        }
        {
            props.projectList!=null?(
        <React.Fragment>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <FeatureTitle><MultiLingualContent contentID="questions_feature_title"/></FeatureTitle>
            <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                paddingBottom:1,
            }}>
        
            <TextField
                size="small"
                multiline
                maxRows={4}
                placeholder={placeholder_text}
                value={textInput}
                onChange={(e) => setTextInput(e.target.value)}
                autoFocus={true}
            />
            </Box>
           
            <Stack spacing={2} direction="row">
                <Button variant="contained" onClick={generate}>
                    {
                        !!loading?(<><CircularProgress size="1rem" color="secondary"/><div style={{marginLeft:7}}><MultiLingualContent contentID="questions_feature_button_text"/></div></>):(<div><MultiLingualContent contentID="questions_feature_button_text"/></div>)                        
                    }
                </Button>
                <Select
                  labelId="DestLngSelect"
                  id="DestLngSelect"
                  value={destLng}
                  label={<MultiLingualContent contentID="select_output_lng"/>}
                  onChange={changeDestinationLanguage}
                >
                    <MenuItem value="ES-ES">Español</MenuItem>
                    <MenuItem value="EN">English</MenuItem>
                    <MenuItem value="DE">Deutsch</MenuItem>
                    <MenuItem value="ES-CA">Català</MenuItem>
                    <MenuItem value="FR">Français</MenuItem>
                    <MenuItem value="IT">Italiano</MenuItem>
                </Select>
                <Select
                  labelId="OrigLngSelect"
                  id="OrigLngSelect"
                  value="5"
                  label={<MultiLingualContent contentID="questions_feature_number_label"/>}
                  onChange={changeNumQuestions}
                >
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                    <MenuItem value="10">10</MenuItem>

                </Select>
            </Stack>


        </Paper>
        <Grid container spacing={2} sx={{mt:2, mb:2}}>
        {questionsOutput.length>0 ?(questionsOutput.reverse().map(entry =>
        
            <Card col="questions" key = {entry.id} cid = {entry.id} content = {entry.text} love={entry.love} cb={()=>del(entry.id)} color={()=> {return Math.floor(Math.random() * 11)}} projectSelected={props.projectSelected} uid={props.uid} projectList={props.projectList} sectionSelectorCB={props.sectionSelectorCB} textInputCB={setTextInput} userPrompt={entry.userPrompt}/>
            )):(null)
        }
        
        </Grid>
        </React.Fragment>
            ):(null)
        }
        </>
    );
}