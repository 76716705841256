// Import FirebaseAuth and firebase.
import React, { useEffect, useState, useContext } from 'react';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import "./Connectors/firebase"
import {Dashboard} from './Runtime';
import { activateFeatures } from './Connectors/firebase';

import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { AppBar } from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async'
import { lazy, Suspense } from 'react'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useNavigate, Navigate } from 'react-router-dom'
import ResponsiveMenu from './Utils/ResponsiveMenu';
import Login from './Login';

import Footer from './GlobalBlocks/Footer';
import { AuthContext } from "./Connectors/context.js";

import MultiLingualContent from './Utils/MultilingualContent';

export default function Payment() {

    const { user, uid } = useContext(AuthContext); 


    const [scrollbar, setScrollbar] = useState(null)
    const [transparent, setTransparent] = useState(true)
    const [scrolled, setScrolled] = useState(false)
    const [top, setTop] = useState(null)


    
    
    return (<div>
        {!!user ? (
          <Navigate to={{ pathname: "/", props:{user, uid}}} />
        ) : (
            <React.Fragment>
            
            <Helmet>
            <meta charset="utf-8" />
            <meta http-equiv="Content-Language" content={<MultiLingualContent contentID="lang"/>}/>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, minimum-scale=1, minimal-ui"
            />
            <link rel="shortcut icon" href="/favicon.ico" />
            <link rel="canonical" href="https://www.rocketme.io" />
            <meta
                name="keywords"
                content={<MultiLingualContent contentID="meta_keywords"/>}
            />
            <meta
                name="description"
                content={<MultiLingualContent contentID="meta_content"/>}
            />
           
            <title>RocketMe - Get Inspired</title>
            </Helmet>
            <Scrollbars
                ref={(e) => {
                if (e !== null) {
                    setScrollbar(e)
                }
                }}
                renderView={(props) => (
                <div
                    {...props}
                    style={{
                    ...props.style,
                    }}
                />
                )}
                onScroll={(e) => {
                setTransparent(scrollbar.viewScrollTop < 100)
                setScrolled(true)
                }}
                autoHide
                style={{ width: '100%', height: '100vh' }}
            >
           
                <div style={{ width: '100%', height: '100%' }}>
                    <div
                        ref={(r) => r && setTop(r)}
                        style={{
                        height: '100vh',
                        width: '100%',
                        backgroundColor: '#534292',
                        backgroundImage:'linear-gradient(to bottom,#654a86,#534292)',
                        backgroundRepeat: 'no-repeat',
                        backgroundAttachment: 'fixed',
                        backgroundSize: 'cover',
                        display: 'flex',
                        justifyContent: 'center',
                        }}
                    >
                        <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                        >
                        <img
                            src={'/rocketmelogov2WhiteMono.svg'}
                            alt="logo"
                            style={{ height: 120, width: 296, justifySelf: 'center' }}
                        />
           
                        <div style={{ padding: 8 }}>
                            Your payment is beign processed...
                            
                        </div>
                        </div>
                    </div>
                    {scrolled && (
                        <Suspense fallback={<CircularProgress />}>
                        <Footer />
                        </Suspense>
                    )}
                    </div>
            
            </Scrollbars>
           </React.Fragment> 
          
        )}
      </div>
    );            
 }
      

 