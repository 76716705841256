import React, { useEffect, useState} from "react";
import { app } from "../Connectors/firebase";
import { doc, getFirestore, onSnapshot} from "firebase/firestore"

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import MultiLingualContent from "../Utils/MultilingualContent";

const db = getFirestore(app);
/*if(process.env.NODE_ENV !== "production")
{
  connectFirestoreEmulator(db, 'localhost', 8080);
}*/
export default function Credits(props)
{
    const [credits, setCredits] =useState(null);

    useEffect(() => { 
      const unsubscribe = onSnapshot(doc(db, "users", props.uid), (doc) =>{

        setCredits(doc.data().credits);
      });
    
      return () => {
          unsubscribe();
      }
  }, []);
   

    return(<>
        {!!credits ? 
            (
                <Typography component="div">
                      <Divider textAlign="right" sx={{pb:1, mt:1}} ><Box sx={{ lineHeight: 'normal', m: 1, color:'GrayText'}}><MultiLingualContent contentID="current_credit_text"/> {credits}</Box></Divider>
                </Typography>
            ):
            (
              null
            )
        }
        </>
    )
}