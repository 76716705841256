import React from 'react';
import { Link as LinkReact } from 'react-router-dom';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { lightTheme } from './ThemeOptions';
import { darkTheme } from './ThemeOptions';
import hallway from '../../static/hallwayDes.jpg'
import { indigo, grey } from '@mui/material/colors';

import slider1 from '../../static/p1.jpg'

import BoltIcon from '@mui/icons-material/Bolt';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import PublicIcon from '@mui/icons-material/Public';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import BlurOnIcon from '@mui/icons-material/BlurOn';

import MultiLingualContent from './MultilingualContent';

import { TextLoop } from "react-text-loop-next";
import MediaQuery from 'react-responsive'

import CookieConsent from "react-cookie-consent";

import { makeStyles } from '@material-ui/core/styles';

import { styled } from '@mui/material/styles';


function Copyright() {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://rocketme.es/">
          RocketMe
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  
  const cards = [
    {
      id:1,
      heading: <MultiLingualContent contentID="cards_text_1_heading"/>,
      caption: <MultiLingualContent contentID="cards_text_1_caption"/>,
      icon: <BoltIcon sx={{ fontSize: 60 }} color="primary"/>
    },
    {
      id:2,
      heading: <MultiLingualContent contentID="cards_text_2_heading"/>,
      caption: <MultiLingualContent contentID="cards_text_2_caption"/>,
      icon: <CircleOutlinedIcon sx={{ fontSize: 60 }} color="primary"/>
    },
    {
      id:1,
      heading: <MultiLingualContent contentID="cards_text_3_heading"/>,
      caption: <MultiLingualContent contentID="cards_text_3_caption"/>,
      icon: <PublicIcon sx={{ fontSize: 60 }} color="primary"/>
    },
  ];



export const MenuLanding = () => {
  const useStyles = makeStyles(theme => ({
    footer: {
      position: 'fixed',
      top: 0,
      width: '100%',
      textAlign: 'center',
      color: lightTheme.palette.getContrastText("#222222"),
      backgroundColor:"#222222",
    }
  }));
  const classes = useStyles();



  return (
        <ThemeProvider theme={lightTheme}>
        <CssBaseline />

        
      <main>
          <section id='home'>
              {/* Hero unit Images credits: Image by <a href="https://pixabay.com/users/bm10777-54683/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=185778">Bernd Müller</a> from <a href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=185778">Pixabay</a>*/}
              <Box
                  sx={{
                      backgroundImage: `
                      linear-gradient(
                        rgba(6, 21, 81, 0.75), 
                        rgba(6, 21, 81, 0.75)
                      ),
                      /* your image */
                      url(${hallway})
                      `,
                      pt: 8,
                      mb:-35,
                      pb: 15,
                      px: "15%"
                  }}
              >
                <MediaQuery minWidth={1224}>
                  <Container maxWidth="sm">
                        <Typography
                        component="h1"
                        variant="h2"
                        fontSize={{
                          lg: 60,
                          md: 60,
                          sm: 50,
                          xs: 30
                        }}
                        align="center"
                        color={lightTheme.palette.getContrastText(indigo[900])}
                        sx={{fontWeight: "bold"}}
                        gutterBottom
                        >
                          <img
                              src={'/rocketmelogov2WhiteMono.svg'}
                              alt="logo"
                              style={{ height: 120, width: 296, justifySelf: 'center' }}
                          /><br></br>
                          <MultiLingualContent contentID="landing_main_copy_first_part"/>
                          <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
                            <MultiLingualContent contentID="landing_main_copy_middle_part_1"/>
                            <MultiLingualContent contentID="landing_main_copy_middle_part_2"/>
                            <MultiLingualContent contentID="landing_main_copy_middle_part_3"/>
                            <MultiLingualContent contentID="landing_main_copy_middle_part_4"/>
                            <MultiLingualContent contentID="landing_main_copy_middle_part_5"/>
                          </TextLoop>
                          <MultiLingualContent contentID="landing_main_copy_final_part"/>
                        </Typography>
                        <Box sx={{ px: 2 }}>
                          <Typography 
                            variant="h6" 
                            align="center" 
                            fontSize={{
                              lg: 25,
                              md: 22,
                              sm: 20,
                              xs: 15
                            }}
                            color={lightTheme.palette.getContrastText(indigo[700])}
                            paragraph
                            >
                            <MultiLingualContent contentID="landing_main_copy_subtext"/>
                          </Typography>
                        </Box>
                        <Stack
                        sx={{ pt: 4 }}
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        >
                          <Button variant="contained" sx={{textAlign:"center", borderRadius: 50}} component={LinkReact} to="/signup">
                            <MultiLingualContent contentID="get_free_account_btn_text"/>
                          </Button>
                        </Stack>
                        <Typography 
                          variant="caption" 
                          align="center"                           
                          color={lightTheme.palette.getContrastText(indigo[700])
                          }>
                            <div><MultiLingualContent contentID="free_credits_line"/></div>
                        </Typography>
                  </Container>
                </MediaQuery>
                <MediaQuery maxWidth={1224}>
                  <Container maxWidth="sm">
                        <Typography
                        component="h1"
                        variant="h2"
                        align="center"
                        fontSize={{
                          lg: 60,
                          md: 50,
                          sm: 50,
                          xs: 40
                        }}
                        color={lightTheme.palette.getContrastText(indigo[900])}
                        sx={{fontWeight: "bold"}}
                        gutterBottom
                        >
                          <img
                              src={'/rocketmelogov2WhiteMono.svg'}
                              alt="logo"
                              style={{ height: 80, width: 197, justifySelf: 'center' }}
                          /><br></br>
                          <MultiLingualContent contentID="landing_main_copy_first_part_mobile_1"/>
                        </Typography>
                        <Box sx={{ px: 2 }}>
                          <Typography 
                            variant="h6" 
                            align="center" 
                            fontSize={{
                              lg: 22,
                              md: 20,
                              sm: 20,
                              xs: 15
                            }}
                            color={lightTheme.palette.getContrastText(indigo[700])}
                            paragraph>
                            <MultiLingualContent contentID="landing_main_copy_subtext"/>
                          </Typography>
                        </Box>
                        <Stack
                        sx={{ pt: 4 }}
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        >
                          <Button variant="contained" sx={{textAlign:"center", borderRadius: 30}} component={LinkReact} to="/signup">
                            <MultiLingualContent contentID="get_free_account_btn_text"/>
                          </Button>
                        </Stack>
                        <Typography variant="caption" align="center" color={lightTheme.palette.getContrastText(indigo[700])}>
                            <div><MultiLingualContent contentID="free_credits_line"/></div>
                        </Typography>
                  </Container>
                </MediaQuery>
                <MediaQuery minWidth={1224}>
                  <Box sx={{py:10, px:5,    display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Card
                      sx={{ height: '100%', display: 'flex', flexDirection: 'row', maxWidth:"900px" }}
                    >
                      <CardMedia
                        component="img"
                        sx={{
                        }}
                        image={slider1}
                        alt="screenshot"
                      />
                    </Card>
                  </Box>
                </MediaQuery>
                <MediaQuery maxWidth={1224}>
                  <Box sx={{py:10, px:5,    display: "flex", alignItems: "center", justifyContent: "center"}}>
                      <Card
                        sx={{ height: '100%', display: 'flex', flexDirection: 'row', minWidth:"300px" }}
                      >
                        <CardMedia
                          component="img"
                          sx={{
                          }}
                          image={slider1}
                          alt="screenshot"
                        />
                      </Card>
                    </Box>
                </MediaQuery>
              </Box>
          </section>
          <section id='benefits'>
          <Box
                  sx={{bgcolor:'#E0E0FF'}}
              >
          <Container sx={{ py: 15 }} maxWidth="md">
            {/* End hero unit */}
            <Grid container spacing={4}>
              {cards.map((card) => (
                <Grid item key={card.id} xs={12} sm={6} md={4}>
                  <Card
                    sx={{border: "none", boxShadow: "none", height: '100%', display: 'flex', flexDirection: 'column',alignItems: "center",  justifyContent:"center", backgroundColor: "rgba(0, 0, 0, 0)"}}
                  >
                    <Box sx={{pt:10}}>{card.icon}</Box>
                    <CardContent sx={{ flexGrow: 1,textAlign:"center"}}>
                      <Typography  color="primary.dark" variant="h6" component="h2" gutterBottom>
                        {card.heading}
                      </Typography>
                      <Typography>{card.caption}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
          </Box>
          </section>
          <section id='witw'>
          <Box
                  sx={{bgcolor:'#F2F7FF'}}
              >
          <Container sx={{ py: 15,textAlign:"center"}} maxWidth="md">
            <Typography variant="h3" component="h2" gutterBottom> <MultiLingualContent contentID="your_success_text"/>
            </Typography>
            <Container sx={{ py: 15 }} maxWidth="md">
              {/* End hero unit */}
              <Grid  container spacing={6}>                
                  <Grid item xs={12} sm={6} md={6}>
                    <Card
                      sx={{border: "none", boxShadow: "none", height: '100%', display: 'flex', flexDirection: 'column',alignItems: "center",  justifyContent:"center", backgroundColor: "rgba(0, 0, 0, 0)"}}
                    >
                      <Box sx={{pt:10}}><TipsAndUpdatesOutlinedIcon sx={{ fontSize: 200 }} color="primary"/></Box>
                      <CardContent sx={{ flexGrow: 1,textAlign:"left"}}>
                        <Typography  color="primary.dark" variant="h6" component="h2" gutterBottom>
                          <MultiLingualContent contentID="get_inspired"/>
                        </Typography>
                        <Typography>
                          <MultiLingualContent contentID="get_inspired_subtext"/>
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Card
                      sx={{border: "none", boxShadow: "none", height: '100%', display: 'flex', flexDirection: 'column',alignItems: "center",  justifyContent:"center", backgroundColor: "rgba(0, 0, 0, 0)"}}
                    >
                      <Box sx={{pt:10}}><BlurOnIcon sx={{ fontSize: 200 }} color="primary"/></Box>
                      <CardContent sx={{ flexGrow: 1,textAlign:"left"}}>
                        <Typography  color="primary.dark" variant="h6" component="h2" gutterBottom>
                          <MultiLingualContent contentID="advanced_language"/>
                        </Typography>
                        <Typography>
                          <MultiLingualContent contentID="advanced_language_subtext"/>
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
              </Grid>
            </Container>
          </Container>
          </Box>
          </section>
          <section id='signup'>
          <Box
                  sx={{
                      backgroundImage: `
                      linear-gradient(
                        rgba(6, 21, 81, 0.75), 
                        rgba(6, 21, 81, 0.75)
                      ),
                      /* your image */
                      url(${hallway})
                      `,
                      pt: 8,
                      pb: 5,
                      px: "15%"
                  }}
              >
                <Container maxWidth="sm"> 
                      <Typography  color="primary" variant="h6" component="h2" align="center" gutterBottom>
                      <MultiLingualContent contentID="get_inspired_now"/>
                      </Typography>
                      <MediaQuery minWidth={1224}>
                        <Typography
                        component="h1"
                        variant="h3"
                        align="center"
                        fontSize={{
                          lg: 60,
                          md: 50,
                          sm: 40,
                          xs: 30
                        }}
                        color={lightTheme.palette.getContrastText(indigo[900])}
                        sx={{fontWeight: "bold"}}
                        gutterBottom
                        >
                        <MultiLingualContent contentID="get_inspired_now_subtext"/>
                        </Typography>
                      </MediaQuery>
                     
                      <MediaQuery maxWidth={1224}>
                        <Typography
                        component="h1"
                        variant="h3"
                        align="center"
                        fontSize={{
                          lg: 60,
                          md: 50,
                          sm: 40,
                          xs: 30
                        }}
                        color={lightTheme.palette.getContrastText(indigo[900])}
                        sx={{fontWeight: "bold"}}
                        gutterBottom
                        >
                        <MultiLingualContent contentID="get_inspired_now_subtext"/>
                        </Typography>
                      </MediaQuery>

                      <Stack
                      sx={{ pt: 4 }}
                      direction="row"
                      spacing={2}
                      justifyContent="center"
                      >
                      <Button variant="contained" sx={{textAlign:"center", borderRadius: 50}} component={LinkReact} to="/signup"><MultiLingualContent contentID="get_free_account_btn_text"/></Button>
                      </Stack>
                      <Typography variant="caption" align="center" color={lightTheme.palette.getContrastText(indigo[700])}>
                          <div><MultiLingualContent contentID="free_credits_line"/></div>
                      </Typography>
                </Container>
                
              </Box>
          </section>
      </main>
        {/* Footer */}
        <Box className={classes.footer}>
          <Typography mr={2} my={1} fontSize={12}>Powered by: Datacellnet Technology</Typography>
        </Box>
        <Box sx={{ pt: 4, pb:4 }} bgcolor="primary.main" component="footer">
          <Copyright />
        </Box>
        <script id="CookieDeclaration" src="https://consent.cookiebot.com/3d88b238-0a99-494b-8aab-e97a9166db6e/cd.js" type="text/javascript" async></script>
        
      </ThemeProvider>
  )
}

//<CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
