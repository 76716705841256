import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default function FormDialog (props){
 
    return(
    <div>
      <Dialog open={true} onClose={props.cbCancel}>
        <DialogTitle>{props.label}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.text}
          </DialogContentText>
          <TextField
            onChange={(event)=>props.cbChange(event.target.value)}
            autoFocus
            margin="dense"
            id="textfield"
            label={props.label}
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.cbOk}>{props.txtOk}</Button>
          <Button onClick={props.cbCancel}>{props.txtCancel}</Button>
        </DialogActions>
      </Dialog>
    </div>)
}