import React from 'react';
import { useState, useEffect} from "react";



import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import MultiLingualContent from './MultilingualContent';

export function ScreenDialog(props)
{
    const [DisplayError, setDisplayError] =useState(props.DisplayError)
    const [DestProject, setDestProject] = useState("")
    const [selectedProject, setSelectedProject] = useState(props.DisplayError.other.select?props.DisplayError.other.select.selected:null);

    const changeProject=(e)=>{
        setSelectedProject(e.target.value);
        setDestProject(e.target.value);
    }

    const project = MultiLingualContent({contentID:"project"});
    return(
        <Dialog
            open={true}
            onClose={DisplayError.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {DisplayError.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {DisplayError.msg}
                </DialogContentText>
            {DisplayError.other.select?(
                <Select
                labelId="projectSelect"
                id="projectSelect"
                value={selectedProject}
                label={project}
                onChange={changeProject}
              >
                {DisplayError.other.select.list.map((row) => (
                  <MenuItem key={row.id} value={row.pid}>{row.name}</MenuItem>
                ))}                
              </Select>
            ):(null)}



            </DialogContent>

            <DialogActions>
                {DisplayError.other.select?(DisplayError.buttons.b1!=null ? (<Button onClick={()=>DisplayError.buttons.b1.cb(DestProject)} autoFocus> {DisplayError.buttons.b1.text} </Button>):(null)):(
                DisplayError.buttons.b1!=null ? (<Button onClick={DisplayError.buttons.b1.cb} autoFocus> {DisplayError.buttons.b1.text} </Button>):(null))}
                {DisplayError.buttons.b2!=null ? (<Button onClick={DisplayError.buttons.b2.cb}> {DisplayError.buttons.b2.text} </Button>):(null)}                    
                {DisplayError.buttons.b3!=null ? (<Button onClick={()=>{DisplayError.buttons.b3.cb(DisplayError.buttons.b3.args)}}> {DisplayError.buttons.b3.text} </Button>):(null)}    
            </DialogActions>
        </Dialog>
    )
}