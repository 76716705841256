
import React, { useEffect, useState } from "react";
import {app, getFeatures} from "./firebase";


export const AuthContext = React.createContext();


export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const setUserData=(data)=>{
      if(data)
      {
        setUser(data);
      }
    }

  useEffect(() => {
    app.auth().onAuthStateChanged(setUserData);
    
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};
