import React from "react";

import CurrentProjects from "./CurrentProjects";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

export class GetInspired extends React.Component
{
    constructor(props) {
        super(props);
      }
    

    render() {
        return(<>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    </Paper>
                </Grid>                
            </Grid>
        </>)
    };
}