import React from "react";


import Grid from '@mui/material/Grid';

import NewQuestions from './newQuestions';


export class Questions extends React.Component
{

    render() {
        return(<>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <NewQuestions uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB}/>
                </Grid>                
            </Grid>
        </>)
    };
}