import React from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from 'firebase/compat/app'
import './firebaseui-styling.global.css'


import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import MultiLingualContent from './Utils/MultilingualContent'


// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  //signInFlow: 'popup',
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],  
  // Terms of service url.
  tosUrl: '<your-tos-url>',
  // Privacy policy url.
  privacyPolicyUrl: '<your-privacy-policy-url>',
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: async (result) =>{

        const userInfo = result.additionalUserInfo;
        
        /*if(userInfo.isNewUser && userInfo.providerId === 'password')
        {
            try{
            await result.user.sendEmailVerification();
            }catch(e){
                console.log("Unable to send email");
            }
        }*/
        
    },
  },
};


const Login = ({ setComponents }) => {
  return (
    <React.Fragment>
        <Paper elevation={3} sx=
        {{
          minWidth:300,
                  
        }}>
         
          <Box
          sx={{
            height: 20,        
            display: 'table-row',
            justifyContent: 'center',
          }}>

            <h3
              style={{
                  marginTop: 25,
                  color: '#654a86',
                  textAlign: 'center',
                  fontWeight: 'normal',
                  fontSize: 20,
                  fontFamily: 'Roboto'
                  
              }}
              >
              <MultiLingualContent contentID="sing_up_or_login"/>
            </h3>
            
          </Box>
          
          <Box
          sx={{
            height: 250,        
            display: 'table-row',
            justifyContent: 'center',
          }}>
            <StyledFirebaseAuth sx={{alignContent:'center',}} uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            
          </Box>
          
        </Paper>
    </React.Fragment>
  )
}

export default Login