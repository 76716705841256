import React, { useEffect, useState, useRef} from "react";
import { app } from "../Connectors/firebase";
import { doc, query, onSnapshot, getFirestore, connectFirestoreEmulator, setDoc, deleteDoc,collection} from "firebase/firestore"


import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import { Box, IconButton } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Utils/Title';


import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoneIcon from '@mui/icons-material/Done';
import FormDialog from "../Utils/PopupDialog";

import MultiLingualContent from '../Utils/MultilingualContent'
import MediaQuery from "react-responsive";

// Generate Order Data
function createData(id, date, name, description, credits) {
  return { id, date, name, description, credits};
}



const db = getFirestore(app);

/*if(process.env.NODE_ENV !== "production")
{
  connectFirestoreEmulator(db, 'localhost', 8080);
}*/

export default function CurrentProjects(props)
{
    const [projectList, setprojectList] = useState([]);
    const [WantsToAddProject, setWantsToAddProject] =useState(false);
    const [WantsToEditProject, setWantsToEditProject] =useState(null);
    const [WantsToDeleteProject, setWantsToDeleteProject] = useState(false);
    const [ProjectToBeDeletedPermanently, setProjectToBeDeletedPermanently] = useState(null);

    const newProjectFrom = useRef(null);

    const ProjectMobile =((props)=>{
        return(
            <>
                
            </>
        );
    })
    function Projects(rows, wantsToAdd) {
        const currentDate = new Date();
        const confirm_deletion_text = MultiLingualContent({contentID:"confirm_deletion"});
        return (
          <React.Fragment>
            <Title><MultiLingualContent contentID="project"/></Title>
            <MediaQuery minWidth={600}>
            {!!WantsToDeleteProject?(<FormDialog label={<MultiLingualContent contentID="delete_project"/>} text={confirm_deletion_text+WantsToDeleteProject} cbCancel={()=>{setWantsToDeleteProject(null)}} cbOk={()=>permanentlyDeleteProject(WantsToDeleteProject)} cbChange={setProjectToBeDeletedPermanently} txtCancel={<MultiLingualContent contentID="cancel"/>} txtOk={<MultiLingualContent contentID="delete_project"/>}/>):(null)}
            <form ref={newProjectFrom}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><MultiLingualContent contentID="created"/></TableCell>
                  <TableCell><MultiLingualContent contentID="name"/></TableCell>
                  <TableCell><MultiLingualContent contentID="short_description"/></TableCell>
                  <TableCell align="right"><MultiLingualContent contentID="credits_used"/></TableCell>                  
                  <TableCell align="center"><MultiLingualContent contentID="actions"/></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectList?(rows.map((row) => (
                    WantsToEditProject===row.id?(
                    <TableRow key={row.id}>
                        <TableCell><TextField name="date" id="date" defaultValue={row.date} style={{display:'none'}}  />{row.date}</TableCell>
                        <TableCell>
                            <TextField name="id" id="id" label={<MultiLingualContent contentID="id"/>} defaultValue={row.id} style={{display:'none'}}  />
                            <TextField name="name" id="name" label={<MultiLingualContent contentID="name"/>} variant="outlined" size="small" color="secondary" defaultValue={row.name} focused /></TableCell>
                        <TableCell><TextField name="desc" id="desc" label={<MultiLingualContent contentID="description"/>} variant="outlined" size="small" color="secondary" defaultValue={row.description} focused /></TableCell>
                        <TableCell align="right">{row.credits}</TableCell>          
                        <TableCell>              
                            <IconButton style={{ color: 'green' }} aria-label={<MultiLingualContent contentID="save"/>} component="span" onClick={saveEditProject}>
                                <DoneIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow> ):(
                    <TableRow key={row.id}>
                        <TableCell>{row.date}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell align="right">{row.credits}</TableCell>
                        <TableCell>
                            <IconButton color="primary" aria-label={<MultiLingualContent contentID="edit"/>} component="span" onClick={()=>editProject(row.id)}>
                                <EditIcon />
                            </IconButton>
                            <IconButton style={{ color: 'red' }} aria-label={<MultiLingualContent contentID="remove"/>} component="span" onClick={()=>deleteProject(row.id)}>
                                <DeleteForeverIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>)
                ))):(null)}
                {wantsToAdd?(
                <TableRow >
                    <TableCell key="999999999">{currentDate.toLocaleString()}</TableCell>
                    <TableCell><TextField name="name" id="name" label={<MultiLingualContent contentID="name"/>} variant="outlined" size="small" color="secondary" focused/></TableCell>
                    <TableCell><TextField name="desc" id="desc" label={<MultiLingualContent contentID="description"/>} variant="outlined" size="small" color="secondary" focused/></TableCell>
                    <TableCell>
                        <IconButton color="primary" aria-label={<MultiLingualContent contentID="add"/>} component="span" onClick={persistProject}>
                            <AddCircleIcon />
                        </IconButton>
                        <IconButton style={{ color: 'red' }} aria-label={<MultiLingualContent contentID="remove"/>} component="span" onClick={cancelProject}>
                            <RemoveCircleIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
                
                ):( null )}
              </TableBody>
            </Table>
            </form>
            {!wantsToAdd?(
            <Link color="primary" href="#" onClick={addProject} sx={{ mt: 3 }}>
                <Box sx={{
                    display:"flex",
                    flexDirection: "row",
                    justifyContent: "center"
                }}>
                    <AddCircleIcon /><div style={{marginLeft:7}}><MultiLingualContent contentID="add_new_project"/></div>
                </Box>
            </Link>
            ):( null )}
            </MediaQuery>
            {/*Phone and tablets */}
            <MediaQuery maxWidth={600}>
            {!!WantsToDeleteProject?(<FormDialog label={<MultiLingualContent contentID="delete_project"/>} text={confirm_deletion_text+WantsToDeleteProject} cbCancel={()=>{setWantsToDeleteProject(null)}} cbOk={()=>permanentlyDeleteProject(WantsToDeleteProject)} cbChange={setProjectToBeDeletedPermanently} txtCancel={<MultiLingualContent contentID="cancel"/>} txtOk={<MultiLingualContent contentID="delete_project"/>}/>):(null)}
            <form ref={newProjectFrom}>
            <Table size="small">
            <TableHead>
                <TableRow>
                  <TableCell><MultiLingualContent contentID="name"/></TableCell>
                  <TableCell align="right"><MultiLingualContent contentID="credits_used"/></TableCell>                  
                  <TableCell align="center"><MultiLingualContent contentID="actions"/></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectList?(rows.map((row) => (
                    WantsToEditProject===row.id?(
                    <TableRow key={row.id}>
                        <TableCell><TextField name="date" id="date" defaultValue={row.date} style={{display:'none'}}  />{row.date}</TableCell>
                        <TableCell>
                            <TextField name="id" id="id" label={<MultiLingualContent contentID="id"/>} defaultValue={row.id} style={{display:'none'}}  />
                            <TextField name="name" id="name" label={<MultiLingualContent contentID="name"/>} variant="outlined" size="small" color="secondary" defaultValue={row.name} focused /></TableCell>
                        <TableCell><TextField name="desc" id="desc" label={<MultiLingualContent contentID="description"/>} variant="outlined" size="small" color="secondary" defaultValue={row.description} focused /></TableCell>
                        <TableCell align="right">{row.credits}</TableCell>          
                        <TableCell>              
                            <IconButton style={{ color: 'green' }} aria-label={<MultiLingualContent contentID="save"/>} component="span" onClick={saveEditProject}>
                                <DoneIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow> 
                    ):(
                    <TableRow key={row.id}>
                        <TableCell>{row.name}{' - '}{row.description}{'\n'}{row.date}</TableCell>
                        <TableCell align="right">{row.credits}</TableCell>
                        <TableCell>
                            <IconButton color="primary" aria-label={<MultiLingualContent contentID="edit"/>} component="span" onClick={()=>editProject(row.id)}>
                                <EditIcon />
                            </IconButton>
                            <IconButton style={{ color: 'red' }} aria-label={<MultiLingualContent contentID="remove"/>} component="span" onClick={()=>deleteProject(row.id)}>
                                <DeleteForeverIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>)
                ))):(null)}
                {wantsToAdd?(
                <TableRow >
                    <TableCell key="999999999">
                        <TextField name="name" id="name" label={<MultiLingualContent contentID="name"/>} variant="outlined" size="small" color="secondary"/>
                        <TextField name="desc" id="desc" label={<MultiLingualContent contentID="description"/>} variant="outlined" size="small" color="secondary"/>
                    </TableCell>
                    <TableCell>
                    <IconButton color="primary" aria-label={<MultiLingualContent contentID="add"/>} component="span" onClick={persistProject}>
                            <AddCircleIcon />
                        </IconButton>
                    </TableCell>
                    <TableCell>
                        <IconButton style={{ color: 'red' }} aria-label={<MultiLingualContent contentID="remove"/>} component="span" onClick={cancelProject}>
                            <RemoveCircleIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
                
                ):( null )}
              </TableBody>
            </Table>
            </form>
            {!wantsToAdd?(
            <Link color="primary" href="#" onClick={addProject} sx={{ mt: 3 }}>
                <Box sx={{
                    display:"flex",
                    flexDirection: "row",
                    justifyContent: "center"
                }}>
                    <AddCircleIcon /><div style={{marginLeft:7}}><MultiLingualContent contentID="add_new_project"/></div>
                </Box>
            </Link>
            ):( null )}
            </MediaQuery>
          </React.Fragment>
        );
      }

    function editProject(id) {
        setWantsToEditProject(id);
    }

    function saveEditProject(event)
    {
        event.preventDefault();
        const form = newProjectFrom.current;

        //Looks for repeated project name
        for(var i=0; i<projectList.length; i++)
        {
            if(projectList[i].name === form['name'].value  && projectList[i].id !== form['id'].value)
            {
                const project_exists = MultiLingualContent({contentID:"project_text"});
                const already_exists = MultiLingualContent({contentID:"already_exists"});

                alert( project_exists + form['name'].value + already_exists);
                return(null);
            }
        }


        setDoc(doc(db,"users", props.uid, "projects",form['id'].value),{
            date: form['date'].value,
            name: form['name'].value,
            description: form['desc'].value,
            credits:0
        }).then(()=>{
            setWantsToEditProject(false);
            console.log("Success");
        }).catch((e)=>{
            setWantsToEditProject(false);
            console.log(e);
            const project_creation_error_alert= MultiLingualContent({contentID:"project_creation_error_alert"});
            alert(project_creation_error_alert)
        });
    }

    function deleteProject(id) {
        setWantsToDeleteProject(id);
    }

    function permanentlyDeleteProject(id){
        if(ProjectToBeDeletedPermanently === id)
        {
            
            deleteDoc(doc(db,"users", props.uid, "projects",id)).then(()=>{
                console.log("Project Deleted");
                setProjectToBeDeletedPermanently(null);
                setWantsToDeleteProject(null);
            }).catch((e)=>{
                setProjectToBeDeletedPermanently(null);
                setWantsToAddProject(true);
                console.log(e);
                const project_creation_error_alert= MultiLingualContent({contentID:"project_creation_error_alert"});

                alert(project_creation_error_alert)
            });
           
        }else
        {
            const incorrect_number_alert= MultiLingualContent({contentID:"incorrect_number_alert"});

            alert(incorrect_number_alert);
            setWantsToDeleteProject(null);
        }
    }

    function addProject(event) {
        event.preventDefault();
        setWantsToAddProject(true);
    }
    function cancelProject(event) {
        event.preventDefault();
        setWantsToAddProject(false);
    }

    function persistProject(event) {
        event.preventDefault();
        const form = newProjectFrom.current;

        //Looks for repeated project name
        if(projectList)
        {
            for(var i=0; i<projectList.length; i++)
            {
                if(projectList[i].name === form['name'].value)
                {
                     const project_exists = MultiLingualContent({contentID:"project_text"});
                     const already_exists = MultiLingualContent({contentID:"already_exists"});

                     alert( project_exists + form['name'].value + already_exists);
                    return(null);
                }
            }
        }

        const timestamp = Date.now();
        const datetime = new Date();
        setDoc(doc(db,"users", props.uid, "projects",timestamp.toString()),{
            date: datetime.toLocaleString(),
            name: form['name'].value,
            description: form['desc'].value,
            credits:0
        }).then(()=>{
            setWantsToAddProject(false);
            console.log("Success");
        }).catch((e)=>{
            setWantsToAddProject(true);
            console.log(e);
            const project_creation_error_alert = MultiLingualContent({contentID:"project_creation_error_alert"});

            alert(project_creation_error_alert)
        });
    }
    useEffect(() => {
        const userProjects = query(collection(db, '/users/'+props.uid+'/projects'));
        const unsuscribe = onSnapshot(userProjects, (doc) => {  
            if (doc.size) {
                let list=[];
                let count=0;
                doc.forEach((doc) => {
                    count=count+1;
                    list.push(createData(doc.id, doc.data().date, doc.data().name, doc.data().description, doc.data().credits));
                });
                //console.log(list);
                setprojectList(list);
            }else{
                setprojectList(null);
                console.log("Could not get docs... Retry, please.");
            }
        });
        return () => {
            unsuscribe();
        }
    }, []);
    
    return(
    <>{WantsToAddProject? Projects(projectList, true):Projects(projectList, false)}</>)
} 
