// Import FirebaseAuth and firebase.
import React, { useState, useContext } from 'react';

import 'firebase/compat/auth';
import "./Connectors/firebase"

import { Helmet } from 'react-helmet-async'
import { Navigate } from 'react-router-dom'


import Footer from './GlobalBlocks/Footer';
import { AuthContext } from "./Connectors/context.js";
import {MenuLanding} from "./Utils/MenuLanding";
import { Suspense } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { Scrollbars } from 'react-custom-scrollbars-2'
import MultiLingualContent from './Utils/MultilingualContent'


export default function Landing() {
    const { user, uid } = useContext(AuthContext); 

    const [scrollbar, setScrollbar] = useState(null)
    const [transparent, setTransparent] = useState(true)
    const [scrolled, setScrolled] = useState(false)
    const [top, setTop] = useState(null)
  
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [isVerified, setIsVerified] = useState(false);

    
    
    return (<div>
        {!!user ? (
          <Navigate to={{ pathname: "/app", props:{user, uid}}} />
        ) : (
            <React.Fragment>
            
            <Helmet>
            <meta charset="utf-8" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, minimum-scale=1, minimal-ui"
            />
            <link rel="shortcut icon" href="/favicon.ico" />
            <link rel="canonical" href="https://www.rocketme.ai" />
            <meta http-equiv="Content-Language" content={<MultiLingualContent contentID="lang"/>}/>
            <meta
                name="keywords"
                content={<MultiLingualContent contentID="meta_keywords"/>}
            />
            <meta
                name="description"
                content={<MultiLingualContent contentID="meta_content"/>}
            />
           
            <title>RocketMe - Get Inspired</title>
            </Helmet>
            <Scrollbars
                ref={(e) => {
                if (e !== null) {
                    setScrollbar(e)
                }
                }}
                renderView={(props) => (
                <div
                    {...props}
                    style={{
                    ...props.style,
                    }}
                />
                )}
                onScroll={(e) => {
                setTransparent(scrollbar.viewScrollTop < 100)
                setScrolled(true)
                }}
                autoHide
                style={{ width: '100%', height: '100vh' }}
            >
           
                <div style={{ width: '100%', height: '100%' }}>
                    <MenuLanding/>
                </div>
            
            </Scrollbars>
           </React.Fragment>)} 
          

      </div>
    );            
 }
      

 