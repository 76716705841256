import React from "react";


import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import NewCompleteText from './newCompleteText';


export class CompleteText extends React.Component
{
       constructor(props) {
        super(props);
    }
    render() {
        return(<>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <NewCompleteText uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB}/>
                </Grid>                
            </Grid>
        </>)
    };
}