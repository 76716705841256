
import React, { useEffect, useState} from "react";


import { styled} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AddCircleIcon from '@mui/icons-material/AddCircle';



import GetMenu from './GlobalBlocks/GetMenu'
import { MainContent } from './sections/MainContent';

import Button from '@mui/material/Button';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import { app } from "./Connectors/firebase";
import { query, onSnapshot, getFirestore, collection} from "firebase/firestore"
import MultiLingualContent from "./Utils/MultilingualContent";
import LanguageButton from "./Utils/LngBtn";
import AppBar from "@mui/material/AppBar";

import MediaQuery from 'react-responsive'

import MenuIcon from '@material-ui/icons/Menu'

import { autocompleteClasses, SwipeableDrawer } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles((theme) =>({
  logoDrawer:{
    marginRight:"auto",
    padding:[0,0,0,0],
  },
}));

function createData(id, pid,  date, name, description, credits) {
  return { id, pid, date, name, description, credits};
}

const db = getFirestore(app);

/*if(process.env.NODE_ENV !== "production")
{
  connectFirestoreEmulator(db, 'localhost', 8080);
}*/

const AppBarOwn = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const drawerWidth = 240;
  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: theme.spacing(7),
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
          },
        }),
      },
    }),
  );


export default function Layout(props)
{
    const [open, setOpen] = useState(true);
    const [project, setProject] = useState("1");
    const [pid, setPID] = useState(undefined);
    const [projectList, setprojectList] = useState([]);
    const [section, setSection] = useState(props.section);
    const [sectionArgs, setSectionArgs] = useState(props.sectionArgs !== undefined ? props.sectionArgs: false);

    const styles = useStyles();

    useEffect(() => {
      const userProjects = query(collection(db, '/users/'+props.uid+'/projects'));
      const unsuscribe = onSnapshot(userProjects, (doc) => {  
          if (doc.size) {
              let list=[];
              let count=0;
              doc.forEach((doc) => {
                  count=count+1;
                  list.push(createData(count, doc.id ,doc.data().date, doc.data().name, doc.data().description, doc.data().credits));
              });
              //console.log(list);
              setprojectList(list);
              if(list !== undefined)
                {
                  setPID(list[project-1].pid);
                }
          }else{
              setprojectList(null);
              console.log("Could not get docs... Retry, please.");
          }
      });
      return () => {
          unsuscribe();
      }
  }, [project, props.uid]);
  
    const toggleDrawer = () => {
      setOpen(!open);
    };

    function setSectionWithArgs(section, args)
    {
      setSectionArgs(args);
      setSection(section);
    }

    const changeProject = (event) => {
      if(event.target.value !== 999999999)
      {
        setProject(event.target.value);
        setPID(projectList[event.target.value-1].pid)
      }
    };
    const TopBar =(   
    <AppBarOwn position="absolute" open={open}>
        <Toolbar
            sx={{
            pr: '24px', // keep right padding when drawer closed
            }}
        >
            <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
            }}
            >
            <img
              src={'/rocketmelogov2SoloLogoWhite.svg'}
              alt={<MultiLingualContent contentID="show_menu_alt"/>}
              style={{ height: 32, width: 32, justifySelf: 'center'}}
            />
            </IconButton>
            <Typography
            component="h1"
            variant="h6"
            color="white"
            noWrap
            sx={{ flexGrow: 1 }}
            >
            {section}            
            </Typography>
            {!!projectList ?(
              <FormControl sx={{ m: 1, minWidth: 155, }} size="small">
                <InputLabel id="projectSelect"><MultiLingualContent contentID="selected"/></InputLabel>
                <Select
                  sx={{
                    color:"white",
                  }}
                  labelId="projectSelect"
                  id="projectSelect"
                  value={project}
                  label={<MultiLingualContent contentID="project"/>}
                  onChange={changeProject}
                >
                  {projectList.map((row) => (
                    <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                  ))}
                    <MenuItem key="999999999" onClick={()=>{setSection('Dashboard')}} value={999999999}><AddCircleIcon /><div style={{marginLeft:7}}><MultiLingualContent contentID="add_project"/></div></MenuItem>
                </Select>
              </FormControl>)
              : ( <Button variant="contained" size="small" color="error" onClick={(e)=>setSection('Dashboard')}><MultiLingualContent contentID="need_to_create_project_before"/></Button> )
            }
            <LanguageButton/>
        </Toolbar>
    </AppBarOwn>
    )

  
      const SideMenu=(
          <Drawer variant="permanent" open={open}>
            <Toolbar
                sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
                }}
            >
                <img
                  src={'/rocketmelogov2Black.svg'}
                  alt="Logo"
                  style={{ height: 60, width: 148, justifySelf: 'center'}}
                />
                <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
                </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav" >
              <GetMenu uid={props.uid} menu={props.menu} cb={setSection} />
            </List>
          </Drawer>
      )
  

    const MobileMenus=(
      <>
      <AppBar position="absolute" open={open}>
          <Toolbar
          >
            <img
                src={'/rocketmelogov2SoloLogoWhite.svg'}
                alt={<MultiLingualContent contentID="show_menu_alt"/>}
                style={{ height: 32, width: 32, justifySelf: 'center'}}
              />
              <Typography
              component="h1"
              variant="h6"
              color="white"
              noWrap
              sx={{ flexGrow: 1 }}
              >
              {section}            
              </Typography>
              {!!projectList ?(
                <FormControl sx={{ m: 1, minWidth: 155, }} size="small">
                  <InputLabel id="projectSelect"><MultiLingualContent contentID="selected"/></InputLabel>
                  <Select
                    sx={{
                      color:"white",
                    }}
                    labelId="projectSelect"
                    id="projectSelect"
                    value={project}
                    label={<MultiLingualContent contentID="project"/>}
                    onChange={changeProject}
                  >
                    {projectList.map((row) => (
                      <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                    ))}
                      <MenuItem key="999999999" onClick={()=>{setSection('Dashboard')}} value={999999999}><AddCircleIcon /><div style={{marginLeft:7}}><MultiLingualContent contentID="add_project"/></div></MenuItem>
                  </Select>
                </FormControl>)
                : ( <Button variant="contained" size="small" color="error" onClick={(e)=>setSection('Dashboard')}><MultiLingualContent contentID="need_to_create_project_before"/></Button> )
              }
              <IconButton>
                <MenuIcon onClick={()=> toggleDrawer()}/>
              </IconButton>
          </Toolbar>


        <SwipeableDrawer anchor="top" open={open} onOpen={()=> toggleDrawer()} onClose={()=>toggleDrawer()}>
            <Toolbar disableGutters
                sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [4],              
                py: [1],
                }}
            >
              <div className={styles.logoDrawer}>
                <img
                  src={'/rocketmelogov2Black.svg'}
                  alt="Logo"
                  style={{ height: 60, width: 148, justifySelf: 'center'}}
                />
              </div>
              <LanguageButton/>
              <IconButton onClick={toggleDrawer} sx={{marginLeft:'auto', p:[0,0,0,0]}}>
                <CloseIcon />
              </IconButton>
               
            </Toolbar>
            <Divider />
              <List component="nav" >
                <GetMenu uid={props.uid} menu={props.menu} cb={setSection} cbClose={toggleDrawer}/>
              </List>
        </SwipeableDrawer>
        </AppBar>
      </>
  )
  
  

        
   // if(props.uid)
  //  {
   //   getFeatures(props.uid).then((result)=>
   //   {
   //       console.log(result);
   //   });
   // }

  return(<>
      {/*Big Screens*/}
      <MediaQuery minWidth={850}>
        {TopBar}
        {SideMenu}
        <MainContent section={section} uid={props.uid} email={props.email} projectList={projectList} projectSelected={pid} sectionSelectorCB={setSectionWithArgs} args={sectionArgs?sectionArgs:false}/>

        
      </MediaQuery>

      {/*Small screens*/}
      <MediaQuery maxWidth={850}>
        {MobileMenus}
        <MainContent section={section} uid={props.uid} email={props.email} projectList={projectList} projectSelected={pid} sectionSelectorCB={setSectionWithArgs} args={sectionArgs?sectionArgs:false}/>
      </MediaQuery>
      </>
  );
}



