import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

import 'firebase/compat/auth';

import { AuthContext} from './Connectors/context';
import Layout from './Layout'
import { Navigate } from 'react-router-dom'
import { lightTheme } from './Utils/ThemeOptions';
import { darkTheme } from './Utils/ThemeOptions';

import MultiLingualContent from './Utils/MultilingualContent';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import MediaQuery from 'react-responsive';


export class Runtime extends React.Component
{
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state={
         open:true,
        }
      }
      
    
      toggleOpen = () => {
        this.setState({
          ...this.state,
          open: !this.state.open
        });
      };
    render() {
        return(
            <div>
                {!!this.context.user ? (   

                    
                   <ThemeProvider theme={lightTheme}>
                        <MediaQuery minWidth={850}>
                            <Dialog
                            open={this.state.open}
                            onClose={this.toggleOpen}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    <MultiLingualContent contentID="beta_disclamer_title"/>
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <MultiLingualContent contentID="beta_disclamer_text"/>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                <Button onClick={this.toggleOpen} autoFocus>
                                    <MultiLingualContent contentID="ok"/>
                                </Button>
                                </DialogActions>
                            </Dialog>
                        </MediaQuery>
                        <MediaQuery maxWidth={850}>
                            <Dialog
                            fullScreen
                            open={this.state.open}
                            onClose={this.toggleOpen}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    <MultiLingualContent contentID="beta_disclamer_title"/>
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description" >
                                        <MultiLingualContent contentID="beta_disclamer_text"/>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                <Button onClick={this.toggleOpen} autoFocus>
                                    <MultiLingualContent contentID="ok"/>
                                </Button>
                                </DialogActions>
                            </Dialog>
                        </MediaQuery>
                        <Box sx={{ display: 'flex' }}>
                            <CssBaseline />
                            <Layout uid={this.context.user.uid} email={this.context.user.email} section={this.props.section}/>
                        </Box>
                   </ThemeProvider>
                   
              ) : (
             <Navigate to={{ pathname: "/"}} />
             )}
             </div>
        )
    };
}

