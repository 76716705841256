import React from "react";
import Container from '@mui/material/Container';
import firebase from 'firebase/compat/app';
import Toolbar from '@mui/material/Toolbar';

import Box from '@mui/material/Box';
import Credits from '../GlobalBlocks/Credits'

import {Dashboard} from './Dashboard'
import {GetCredits} from './GetCredits'
import {GetInspired} from './GetInspired'

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { PersonalStatements } from "./PersonalStatement";
import { Headlines } from "./Headline";
import { Improvement } from "./Improvement";
import { Summary } from "./Summary";
import { Translation } from "./Translation";
import { Naming } from "./Naming";
import { Questions } from "./Questions";
import { Brainstorms } from "./Brainstorms";
import { Feedback } from "./Feedback";
import { Tagline } from "./Tagline";
import { CompleteText } from "./CompleteText";
import { Texts } from "./Texts";


import {Ad} from "./Ad";

function Copyright(props) {
    return (
      <Typography variant="body2" color="primary.contrastText" align="center" {...props}>
        {'Copyright © '}
        <Link color="primary.contrastText" href="https://rocketme.com/">
          RocketMe
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
export class MainContent extends React.Component
{
    constructor(props) {
        super(props);
      }
    

    render() {

        return(
            
                <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
                >
                <Toolbar />
                <Credits uid={this.props.uid} />
                <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
                {this.props.section==="Dashboard"?
                    (<Dashboard uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB}/>):(null)
                }
                {this.props.section==="Get Inspired"?
                    (<GetInspired uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB}/>):(null)
                }
                {this.props.section==="Personal Statements"?
                    (<PersonalStatements uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB}/>):(null)
                }
                {this.props.section==="Headlines"?
                    (<Headlines uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB}/>):(null)
                }
                {this.props.section==="Improved Texts"?
                    (<Improvement uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB} args={this.props.args}/>)  :(null)
                }
                {this.props.section==="Summarize"?
                    (<Summary uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB}/>):(null)
                }
                {this.props.section==="Translations"?
                    (<Translation uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB} args={this.props.args}/>):(null)
                }
                {this.props.section==="Account"?
                    (<Dashboard uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB}/>):(null)
                }
                {this.props.section==="Get Credits"?
                    (<GetCredits uid={this.props.uid} email={this.props.email} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB}/>):(null)
                }
                {this.props.section==="Texts"?
                    (<Texts uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB}/>):(null)
                }
                {this.props.section==="Namings"?
                    (<Naming uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB}/>):(null)
                }
                {this.props.section==="Brainstorms"?
                    (<Brainstorms uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB}/>):(null)
                }
                {this.props.section==="Questions"?
                    (<Questions uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB}/>):(null)
                }
                {this.props.section==="Ads"?
                    (<Ad uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB}/>):(null)
                }
                {this.props.section==="Feedback"?
                    (<Feedback uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB} args={this.props.args}/>):(null)
                }
                {this.props.section==="Taglines"?
                    (<Tagline uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB}/>):(null)
                }
                {this.props.section==="Complete"?
                    (<CompleteText uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB}/>):(null)
                }
                    <Copyright sx={{ pt: 4 }} />
                </Container>
                </Box>          
        )
    };
}