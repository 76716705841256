import { useContext } from "react";
import { LanguageContext } from "./multilingualCtx.js";
import { Button } from "@mui/material";
export default function LanguageButton () {
  const { language, toggleLanguage } = useContext(LanguageContext);

  return (
    <>
      <Button variant="contained" size="small" onClick={toggleLanguage}>{language}</Button>
    </>
  );
};