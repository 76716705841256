import * as React from 'react';
import Divider from '@mui/material/Divider';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PersonIcon from '@mui/icons-material/Person';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import TranslateIcon from '@mui/icons-material/Translate';
import DashboardIcon from '@mui/icons-material/Dashboard';

import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ShortTextIcon from '@mui/icons-material/ShortText';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import PsychologyIcon from '@mui/icons-material/Psychology';
import GrainIcon from '@mui/icons-material/Grain';
import LogoutIcon from '@mui/icons-material/Logout';
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import MultiLingualContent from '../Utils/MultilingualContent'
import RateReviewIcon from '@mui/icons-material/RateReview';

const auth = getAuth();

const logout = (navigate) =>{

  signOut(auth).then(() => {
    window.location.reload();
}).catch((error) => {
    console.log("Could not log out...");
});
}



export default function GetMenu (props){
  const [openInspiration, setOpenInspiration] = React.useState(false);
  const [openHelpers, setOpenHelpers] = React.useState(false);
  const [openTexts, setOpenTexts] = React.useState(false);

  const navigate = useNavigate();
  const oai_disclamer_text = MultiLingualContent({contentID:"oai_disclamer"});
  const beta_tester_disclamer_text = MultiLingualContent({contentID:"beta_tester_disclamer"});
  
  return(<>    
    <React.Fragment>
      <ListItemButton onClick={()=>{props.cb("Dashboard"); if(props.cbClose){props.cbClose()}}}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={<MultiLingualContent contentID="dashboard_btn"/>} />
      </ListItemButton>
      <ListItemButton onClick={()=>{setOpenTexts(!openTexts); setOpenInspiration(false); setOpenHelpers(false);}}
      sx={openTexts ? { borderBottom:2, borderColor: 'rgba(0,0,0,.1)'}:{ borderBottom:2, borderColor: 'rgba(0,0,0,0)'}}>
          <ListItemIcon>
            <TextSnippetIcon />
          </ListItemIcon>
          <ListItemText primary={<MultiLingualContent contentID="texts_btn"/>} />
          {openTexts ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openTexts} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton onClick={()=>{props.cb("Headlines"); if(props.cbClose){props.cbClose()}}} sx={{ ml: 0, pl:2, py: 0, minHeight: 32, color: 'rgba(0,0,0,.6)',}}>
            <ListItemIcon>
              <ShortTextIcon />
            </ListItemIcon>
            <ListItemText primary={<MultiLingualContent contentID="headlines_btn"/>} />
          </ListItemButton>
          <Divider sx={{ my: 0 }} />
          <ListItemButton onClick={()=>{props.cb("Improved Texts"); if(props.cbClose){props.cbClose()}}} sx={{ ml: 0, pl:2, py: 0, minHeight: 32, color: 'rgba(0,0,0,.6)',}}>
            <ListItemIcon>
              <AutoAwesomeIcon />
            </ListItemIcon>
            <ListItemText primary={<MultiLingualContent contentID="improvement_btn"/>} />
          </ListItemButton>
          <Divider sx={{ my: 0 }} />
          <ListItemButton onClick={()=>{props.cb("Summarize"); if(props.cbClose){props.cbClose()}}} sx={{ ml: 0, pl:2, py: 0, minHeight: 32, color: 'rgba(0,0,0,.6)',}}>
            <ListItemIcon>
              <SummarizeIcon />
            </ListItemIcon>
            <ListItemText primary={<MultiLingualContent contentID="summaries_btn"/>} />
          </ListItemButton>
          <Divider sx={{ my: 0 }} />
          <ListItemButton onClick={()=>{props.cb("Translations"); if(props.cbClose){props.cbClose()}}} sx={{ ml: 0, pl:2, py: 0, minHeight: 32, color: 'rgba(0,0,0,.6)',}}>
            <ListItemIcon>
              <TranslateIcon />
            </ListItemIcon>
            <ListItemText primary={<MultiLingualContent contentID="translation_btn"/>} />
          </ListItemButton>
          <Divider sx={{ my: 0 }} />
        </List>
      </Collapse>

      <ListItemButton onClick={()=>{setOpenTexts(false); setOpenInspiration(false); setOpenHelpers(!openHelpers)}}
      sx={openHelpers ? { borderBottom:2, borderColor: 'rgba(0,0,0,.1)'}:{ borderBottom:2, borderColor: 'rgba(0,0,0,0)'}}>
          <ListItemIcon>
            <AutoFixHighIcon />
          </ListItemIcon>
          <ListItemText primary={<MultiLingualContent contentID="tools_btn"/>} />
          {openHelpers ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openHelpers} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton onClick={()=>{props.cb("Personal Statements"); if(props.cbClose){props.cbClose()}}} sx={{ ml: 0, pl:2, py: 0, minHeight: 32, color: 'rgba(0,0,0,.6)',}}>
            <ListItemIcon>
              <HistoryEduIcon />
            </ListItemIcon>
            <ListItemText primary={<MultiLingualContent contentID="statement_btn"/>} />
          </ListItemButton> 
          <Divider sx={{ my: 0 }} />       
          <ListItemButton onClick={()=>{props.cb("Texts"); if(props.cbClose){props.cbClose()}}} sx={{ ml: 0, pl:2, py: 0, minHeight: 32, color: 'rgba(0,0,0,.6)',}}>
            <ListItemIcon>
              <SpeakerNotesIcon />
            </ListItemIcon>
            <ListItemText primary={<MultiLingualContent contentID="texts_btn"/>} />
          </ListItemButton>
          <Divider sx={{ my: 0 }} />
        </List>
      </Collapse>

      <ListItemButton onClick={()=>{setOpenTexts(false); setOpenInspiration(!openInspiration); setOpenHelpers(false);}}
      sx={openInspiration ? { borderBottom:2, borderColor: 'rgba(0,0,0,.1)'}:{ borderBottom:2, borderColor: 'rgba(0,0,0,0)'}}>
          <ListItemIcon>
            <TipsAndUpdatesIcon />
          </ListItemIcon>
          <ListItemText primary={<MultiLingualContent contentID="inspiration_btn"/>} />
          {openInspiration ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openInspiration} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton onClick={()=>{props.cb("Namings"); if(props.cbClose){props.cbClose()}}} sx={{ ml: 0, pl:2, py: 0, minHeight: 32, color: 'rgba(0,0,0,.6)',}}>
            <ListItemIcon>
              <AutoFixNormalIcon />
            </ListItemIcon>
            <ListItemText primary={<MultiLingualContent contentID="naming_btn"/>} />
          </ListItemButton>
          <Divider sx={{ my: 0 }} />
          <ListItemButton onClick={()=>{props.cb("Taglines"); if(props.cbClose){props.cbClose()}}} sx={{ ml: 0, pl:2, py: 0, minHeight: 32, color: 'rgba(0,0,0,.6)',}}>
            <ListItemIcon>
              <ShortTextIcon />
            </ListItemIcon>
            <ListItemText primary={<MultiLingualContent contentID="taglines_btn"/>} />
          </ListItemButton>
          <Divider sx={{ my: 0 }} />
          <ListItemButton onClick={()=>{props.cb("Ads"); if(props.cbClose){props.cbClose()}}} sx={{ ml: 0, pl:2, py: 0, minHeight: 32, color: 'rgba(0,0,0,.6)',}}>
            <ListItemIcon>
              <AdUnitsIcon />
            </ListItemIcon>
            <ListItemText primary={<MultiLingualContent contentID="ads_btn"/>} />
          </ListItemButton>
          <Divider sx={{ my: 0 }} />
          <ListItemButton onClick={()=>{props.cb("Brainstorms"); if(props.cbClose){props.cbClose()}}} sx={{ ml: 0, pl:2, py: 0, minHeight: 32, color: 'rgba(0,0,0,.6)',}}>
            <ListItemIcon>
              <GrainIcon />
            </ListItemIcon>
            <ListItemText primary={<MultiLingualContent contentID="brain_btn"/>} />
          </ListItemButton>
          <Divider sx={{ my: 0 }} />
          <ListItemButton onClick={()=>{props.cb("Questions"); if(props.cbClose){props.cbClose()}}} sx={{ ml: 0, pl:2, py: 0, minHeight: 32, color: 'rgba(0,0,0,.6)',}}>
            <ListItemIcon>
              <QuestionAnswerIcon />
            </ListItemIcon>
            <ListItemText primary={<MultiLingualContent contentID="questions_btn"/>} />
          </ListItemButton>
          <Divider sx={{ my: 0 }} />
          <ListItemButton onClick={()=>{props.cb("Complete"); if(props.cbClose){props.cbClose()}}} sx={{ ml: 0, pl:2, py: 0, minHeight: 32, color: 'rgba(0,0,0,.6)',}}>
            <ListItemIcon>
              <PsychologyIcon />
            </ListItemIcon>
            <ListItemText primary={<MultiLingualContent contentID="complete_btn"/>} />
          </ListItemButton>
        </List>
      </Collapse>
    </React.Fragment>
    <Divider sx={{ my: 1 }} />
    <ListSubheader component="div" inset>
    <MultiLingualContent contentID="account"/>
    </ListSubheader>
    <ListItemButton onClick={()=>{props.cb("Get Credits"); if(props.cbClose){props.cbClose()}}}>
      <ListItemIcon>
        <CreditCardIcon />
      </ListItemIcon>
      <ListItemText primary={<MultiLingualContent contentID="get_credits"/>} />
    </ListItemButton>
    <ListItemButton onClick={()=>{props.cb("Feedback"); if(props.cbClose){props.cbClose()}}}>
      <ListItemIcon>
        <RateReviewIcon />
      </ListItemIcon>
      <ListItemText primary={<MultiLingualContent contentID="send_feedback"/>} />
    </ListItemButton>
    <ListItemButton onClick={() => logout(navigate)}>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText primary={<MultiLingualContent contentID="log_out"/>} />
    </ListItemButton>
    <ListItemButton onClick={()=>{alert(oai_disclamer_text)}}>
      <ListItemText sx={{whiteSpace:'normal'}} primary={<MultiLingualContent contentID="ai_interaction_disclamer"/>}/>
    </ListItemButton>
  </>
  )
}




