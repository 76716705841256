import React from "react";

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MultiLingualContent from '../Utils/MultilingualContent'


  
  
export class GetCredits extends React.Component
{
    constructor(props) {
        super(props);
      }

      tiers = [
        {
            title: 'Starter',
            price: '5',
            description: [<MultiLingualContent contentID="pack_10000_text_title"/>,<MultiLingualContent contentID="pack_10000_text_desc"/>],
            buttonText: <MultiLingualContent contentID="go"/>,
            buttonLink: "https://buy.stripe.com/7sIcPD4pL04l2pWeUX?",
            buttonVariant: 'outlined',
          },
          {
            title: 'PRO',
            subheader: <MultiLingualContent contentID="most_popular"/>,
            price: '19',
            description: [<MultiLingualContent contentID="pack_200000_text_title"/>,<MultiLingualContent contentID="pack_200000_text_desc"/>],
            buttonText: <MultiLingualContent contentID="go"/>,
            buttonLink: "https://buy.stripe.com/aEUdTH09v8AR1lSaEI?",
            buttonVariant: 'contained',
          },
          {
            title: 'Master',
            price: '49',
            description: [<MultiLingualContent contentID="pack_800000_text_title"/>,<MultiLingualContent contentID="pack_800000_text_desc"/>],
            buttonText: <MultiLingualContent contentID="go"/>,
            buttonLink: "https://buy.stripe.com/fZe6rfcWh3gxc0w149?",
            buttonVariant: 'outlined',
        },
      ];

       updateTier = (tier)=>{
        tier.buttonLink+="prefilled_email=";
        tier.buttonLink+=this.props.email;
        tier.buttonLink+="&";
        tier.buttonLink+="client_reference_id=";        
        tier.buttonLink+=this.props.uid;
       }
       

    
    render() {

        this.tiers.forEach(this.updateTier);
        return(<>
        
            <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {this.tiers.map((tier) => (
            // Pack PRO card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === 'PRO' ? 12 : 6}
              md={4}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.secondary.main[200]
                        : theme.palette.secondary.main[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      €{tier.price}
                    </Typography>
                  </Box>
                    {tier.description.map((line) => (
                      <Typography
                        component="div"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant} href={tier.buttonLink}>
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
        </>)
    };
}

